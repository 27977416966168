<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="formgrid grid">
              <div class="field lg:col-4 xl:col-4 sm:col-6">
                <label for="firstname2">Mês</label>
                <select id="lastname2"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="Status" v-model="mes">
                  <option v-for="mes in meses" :key="mes.value" :value="mes.value">
                    {{ mes.label }}
                  </option>
                </select>
              </div>
              <div class="field lg:col-4 xl:col-4 sm:col-6">
                <label for="lastname2">Ano</label>
                <select id="lastname2"
                  class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                  placeholder="Status" v-model="ano">
                  <option v-for="ano in anos" :key="ano.value" :value="ano.value">
                    {{ ano.label }}
                  </option>
                </select>
              </div>

              <div class="field lg:col-3 xl:col-2 sm:col-63">
                <Button type="button" style="margin-top: 25px; padding: 5px 10px;;" class="mr-2 mb-2 " label="Filtrar"
                  icon="pi pi-search" :loading="loading" @click="filtrar()" />
              </div>
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload moe="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Importar Faturas Implantadas" :loading="loading" style="margin-right: 10px;" severity="primary" icon="pi pi-upload" @click="importar()" />

            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Implantaçoes -->
        <DataTable :loading="loading" ref="dt" csvSeparator=";" :value="faturas" dataKey="id" :paginator="true" :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Faturas Implantadas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>
          <template #empty> Nenhum resultado encontrado. </template>
          <template #loading> Aguarde, carregando... </template>
          <Column field="uc" header="Unidade" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Unidade</span>
              {{ slotProps.data.uc }}
            </template>
          </Column>
          <Column field="mes_ref" header="Mês Referência" :sortable="true" headerStyle="width:15%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Mês Referência</span>
              {{ slotProps.data.mes_ref }}
            </template>
          </Column>

          <Column field="data_emissao" header="Data Emissão" :sortable="true" headerStyle="width:15%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Data Emissão</span>
              {{ slotProps.data.data_emissao }}
            </template>
          </Column>
          
          <Column field="url" header="URL" :sortable="true" headerStyle="width:10%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">URL</span>
              <Button style="padding: 4px; font-size: 12px;;" @click="abrirUrl(slotProps.data)">Site SEFAZ</Button>

            </template>
          </Column>
          <Column field="chave" header="Chave Acesso" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Chave Acesso</span>
              {{ slotProps.data.chave }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      loading: 0,
      mes: String(new Date().getMonth() + 1).padStart(2, '0'),
      ano: new Date().getFullYear(),
      meses: [
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" }

      ],
      anos: [],
      faturas: [],
      fatura: {},

      filters: {},
      submitted: false,



    };
  },

  created() {
    this.initFilters();
  },
  mounted() {

    // Obtendo o ano atual
    const anoAtual = new Date().getFullYear();

    let anos = [];

    // Loop do ano atual até 2017
    for (let ano = anoAtual; ano >= 2024; ano--) {
      anos.push({ label: `${ano}`, value: `${ano}` });
    }

    this.anos = anos;
    // funçoes importadas do arquivo funcoes.js
    this.listarFaturas();




  },


  methods: {

    importar() {

      this.loading = true;
      let data = {
        "mes_referencia": this.ano+"-"+this.mes+"-01"
      }

      this.axios
        .post("/faturas/buscar", data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Faturas importadas com sucesso!",
            life: 3000,
          });
          this.loading = false;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
          this.loading = false;
        });
    },

    abrirUrl(data) {
      navigator.clipboard.writeText(data.chave).then(function () {
       
      }).catch(function (error) {
        console.error('Erro ao copiar texto: ', error);
      });
      window.open('https://dfe-portal.svrs.rs.gov.br/NF3ESSL/DownloadXMLDFe?OrigemSite=1&Ambiente=1&ChaveAcessoDfe='+data.chave);
    },
    filtrar() {
      this.loading = true;
      this.listarFaturas();
    },

    listarFaturas() {
      this.loading = true;
      let data = {
        ano: this.ano,
        mes: this.mes
      }

      this.axios
        .post("/faturas/listar", data)
        .then((response) => {
          this.faturas = response.data.dados;
          this.loading = false;

        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
          this.loading = false;
        });

    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },


    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

  },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>