<template>
  <!-- <div class="grid">
    <div class="xl:col-12 sm:col-10">
      <div class="card mb-0" style=" background-color: #eee;">

        <h5>Perímetro</h5>
        <div class="formgrid grid">
          <div class="field lg:col-4 xl:col-2 sm:col-6">
            <label for="firstname2">Mês</label>
            <select id="lastname2"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Status" v-model="mes">
              <option v-for="mes in meses" :key="mes.value" :value="mes.value">
                {{ mes.label }}
              </option>
            </select>
          </div>
          <div class="field lg:col-4 xl:col-2 sm:col-6">
            <label for="lastname2">Ano</label>
            <select id="lastname2"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Status" v-model="ano">
              <option v-for="ano in anos" :key="ano.value" :value="ano.value">
                {{ ano.label }}
              </option>
            </select>
          </div>
          <div class="field lg:col-3 xl:col-2 sm:col-6">
            <label for="lastname2">Status da Unidade</label>
            <select id="lastname2" type="date"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Status" v-model="staus_unidades">
              <option v-for="unidade in status_dropdown" :key="unidade.value" :value="unidade.value">
                {{ unidade.label }}
              </option>
            </select>
          </div>
          <div class="field lg:col-3 xl:col-2 sm:col-6">
            <label for="lastname2">Modelo</label>
            <select id="lastname2" type="date"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Status" v-model="modelo">
              <option v-for="tensao in modelos" :key="tensao.value" :value="tensao.value">
                {{ tensao.label }}
              </option>

            </select>
          </div>
          <div class="field lg:col-4 xl:col-3 sm:col-6">
            <label for="lastname2" style="white-space: nowrap">Status do Envio</label>
            <select id="lastname2" type="date"
              class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              placeholder="Status" v-model="status">
              <option v-for="status_p in status_processo" :key="status_p.value"
                :value="status_p.value">
                {{ status_p.label }}
              </option>


            </select>
          </div>
          <div class="field lg:col-3 xl:col-2 sm:col-63">
            <Button type="button" rounded style="margin-top: 18px;" class="mr-2 mb-2 " label="Filtrar" icon="pi pi-search" :loading="btnFiltrar" @click="filtrar()" />
          </div>
        </div>
      </div>
    </div>

  </div> -->

  <div class="grid">

    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div class="">
          <div style="text-align: center;">
            <span class="block text-500 font-medium  titulo">Faturas Implantadas <br><small style="color: #ccc;">(Modelo 66)</small></span>
            <div class="text-900 font-medium text-xl valor">{{ faturas_implantadas }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div style="text-align: center;">
          <div>
            <span class="block text-500 font-medium  titulo">XML <br>Capturados <br><small style="color: #ccc;">(Modelo 66)</small></span>
            <div class="text-900 font-medium text-xl valor">{{ xml_capturados }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div style="text-align: center;">
          <div>
            <span class="block text-500 font-medium  titulo">XML não Capturados<br><small style="color: #ccc;">(Motivos Diversos)</small></span>
            <div class="text-900 font-medium text-xl valor">{{ xml_nao_capturados }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div style="text-align: center;">
          <div>
            <span class="block text-500 font-medium  titulo">XML <br> Envidados<br><small style="color: #ccc;">(Sucesso)</small></span>
            <div class="text-900 font-medium text-xl valor">{{ xml_enviados }}</div>
          </div>

        </div>
      </div>
    </div>




    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div style="text-align: center;">
          <div>
            <span class="block text-500 font-medium  titulo">Erros ao <br>Enviar<br><small style="color: #ccc;">(Erros)</small></span>
            <div class="text-900 font-medium text-xl valor">{{ xml_enviados_erro }}</div>
          </div>
        </div>
      </div>
    </div>

   

    <div class="col-12 lg:col-6 xl:col-2">
      <div class="card mb-0">
        <div style="text-align: center;">
          <div>
            <span class="block text-500 font-medium  titulo">Total <br> Envios</span>
            <div class="text-900 font-medium text-xl valor">{{ xml_total }}</div>
          </div>
        </div>
        <span class="text-green-500 font-medium"></span>
        <span class="text-500"></span>
      </div>
    </div>
    <hr style="width: 100%;" />
    <div class="col-12 lg:col-6 xl:col-6">
      <div class="grid">
        <div class="col-12 col-12">
          <div class="card">
            <h5>Evolução da Base no Mês [%] </h5>
            <Chart type="doughnut" :data="pieData2" :options="pieOptions" style="width: 48%" /><br>
          </div>
        </div>
        <!-- <div class="col-12 col-12">
          <div class="card" style="text-align: center;">
            <h5>Consumo [kwh] <img src="/app/front/images/loading.svg" style="width: 30px;float: right;" v-if="loadingBaseConsumo">
            </h5>

            <Chart type="line" :data="evolucaoConsumo" :options="lineOptions" />
          </div>
        </div> -->
      </div>
    </div>

    <div class="col-12 lg:col-6 xl:col-6">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>Evolução da Base [Qtd.] <img src="/app/front/images/loading.svg" style="width: 30px;float: right;"
                v-if="loadingBase"></h5>
            <Chart type="bar" :data="evolucaoBase" :options="barOptions" />

          </div>
        </div>
        <!-- <div class="col-12 col-12">
          <div class="card" style="text-align: center;">
            <h5>Valor [R$] <img src="/app/front/images/loading.svg" style="width: 30px;float: right;" v-if="loadingBaseValor"></h5>
            <Chart type="line" :data="evolucaoValor" :options="lineOptions" />
          </div>
        </div> -->

      </div>
    </div>
<!-- 
    <hr style="width: 100%;" /> -->

    <!-- <div class="col-12 xl:col-12">

      <div class="card">
        <div class="flex align-items-center justify-content-between mb-4">
          <h5>Notícias do Setor Elétrico</h5>

        </div>

        <div>
         <img src="https://amee.com.br/wp-content/uploads/2023/08/ANEEL-projeta-reducao-de-tarifa-1024x640.jpg.webp" width="100" style="display:inline-block; margin: 0 20px;">
          <div style="color: #666; display: inline-block;vertical-align: top ; font-weight: bold;width: 90%;">
            ANEEL projeta redução de tarifa para fabricante chinesa que realizará investimentos no estado da Bahia
            <div style="color: #999;font-weight: normal;margin-top: 10px;">Representantes da BYD, a maior fabricante de veículos elétricos do mundo, se reuniram com a Agência Nacional de Energia Elétrica (ANEEL) para discutir os investimentos da empresa na Bahia e os benefícios... <a href="https://amee.com.br/noticias/aneel-projeta-reducao-de-tarifa-para-fabricante-chinesa-que-realizara-investimentos-no-estado-da-bahia/" target="_blank">Leia Mais</a></div>
          </div>
        

        </div>

        <div style="margin-top: 30px;">
         <img src="https://amee.com.br/wp-content/uploads/2023/08/dia-do-perdao-1024x683.jpg.webp" width="100" style="display:inline-block; margin: 0 20px;">
          <div style="color: #666; display: inline-block;vertical-align: top ; font-weight: bold;width: 90%;">
            Publicado FAQ sobre o “dia do perdão”

            <div style="color: #999;font-weight: normal;margin-top: 10px;">
              A ANEEL disponibilizou um novo guia de perguntas e respostas sobre o “dia do perdão” para geradores que adquiriram outorga de geração e contratos...
              <a href="https://amee.com.br/noticias/publicado-faq-sobre-o-dia-do-perdao/" target="_blank">Leia Mais</a></div>
          </div>
        

        </div>

      </div>
    </div> -->

  </div>
</template>

<script>
import EventBus from '@/AppEventBus';
import ProductService from '../service/ProductService';



export default {

  data() {

    return {
      modelo: 66,
      status:0,
      btnFiltrar: false,
      loadingBase: true,
      loadingConsumo: true,
      loadingBaseValor: true,
      loadingBaseConsumo: true,
      mes: new Date().getMonth() + 1,
      ano: new Date().getFullYear(),
      staus_unidades: "Ativa",
      tipo_tensao: "",
      tipo_pagamento: "",
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#000'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
        }
      },
      lineOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#000'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#000'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
        }
      },
      meses: [
        { label: "Janeiro", value: "1" },
        { label: "Fevereiro", value: "2" },
        { label: "Março", value: "3" },
        { label: "Abril", value: "4" },
        { label: "Maio", value: "5" },
        { label: "Junho", value: "6" },
        { label: "Julho", value: "7" },
        { label: "Agosto", value: "8" },
        { label: "Setembro", value: "9" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" }

      ],
      anos: [],
      status_dropdown: [
        { label: "Ativas", value: "Ativa" },
        { label: "Desativadas", value: "Desativada" },
        { label: "Todas", value: "Todas" }
      ],
      modelos: [
        {},
        { label: "Modelo 66", value: "66" },
        { label: "Modelo 6", value: "6" },
      ],
      status_processo: [
        {},
        { label: "Sucesso", value: "1" },
        { label: "Erro", value: "0" },
      ],
      faturas_implantadas: 0,
      xml_capturados: 0,
      xml_nao_capturados: 0,
      xml_enviados: 0,
      erros_ao_enviar: 0,
      total_envio: 0,

      dropdownItem: [],
      exampleValue: 35,
      products: null,
      pieData2: {
            labels: ['Implantadas %', 'Capturadas %','Enviadas %'],
            datasets: [
              {
                data: [70,10,20],
                backgroundColor: [
                  "#2f4860",
                  "#eee",
                  "#00bb7e",

                ],
                hoverBackgroundColor: [
                  "#2f4860",
                  "#eee",
                  "#00bb7e",
                ]
              }
            ]
          },

      evolucaoBase: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Implantadas',
            backgroundColor: '#2f4860',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            label: 'Capturadas',
            backgroundColor: '#00bb7e',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      },

      items: [
        { label: 'Add New', icon: 'pi pi-fw pi-plus' },
        { label: 'Remove', icon: 'pi pi-fw pi-minus' }
      ],

    }
  },
  productService: null,
  themeChangeListener: null,
  mounted() {

    // Obtendo o ano atual
    const anoAtual = new Date().getFullYear();

    let anos = [];

    // Loop do ano atual até 2017
    for (let ano = anoAtual; ano >= 2024; ano--) {
      anos.push({ label: `${ano}`, value: `${ano}` });
    }

    this.anos = anos;

    this.montarHome();
    this.monterEvolucaoBase();
    this.monterEvolucaoConsumo();
    this.monterEvolucaoValor();


  },
  beforeUnmount() {
    EventBus.off('change-theme', this.themeChangeListener);
  },
  created() {
    this.productService = new ProductService();
  },
  methods: {
    filtrar() {
      this.btnFiltrar = true;
      this.consumoTotal = "";
      this.unidades_ativas= "-";
      this.unidades_desativadas= "-";
      this.aviso_de_corte= "-";
      this.total= "-";
      this.juros= "-";
      this.multas="-";
      this.ultrapassagem_demanda= "-";
      this.ultrapassagem_demanda_reais= "-";
     
      this.montarHome();
      this.monterEvolucaoBase();
      this.monterEvolucaoConsumo();
      this.monterEvolucaoValor();

      this.btnFiltrar = false;
    },

    monterEvolucaoBase() {
      this.evolucaoBase = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Implantadas',
            backgroundColor: '#2f4860',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            label: 'Aguardando Implantação',
            backgroundColor: '#00bb7e',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      };
      this.loadingBase = true;
      let data = {
        data_inicial: this.ano + "-" + this.mes + "-01",
        data_final: this.ano + "-" + this.mes + "-01",
        status: this.staus_unidades,
        tipo_tensao: this.tipo_tensao,
        tipo_pagamento: this.tipo_pagamento,
        ano: this.ano
      }
      this.axios
        .post("/home/evolucao-base", data)
        .then((response) => {

          this.evolucaoBase = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
              {
                label: 'Implantadas',
                backgroundColor: '#2f4860',
                data: [
                  response.data.res_evolucao_implantadas[0] ? response.data.res_evolucao_implantadas[0]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[1] ? response.data.res_evolucao_implantadas[1]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[2] ? response.data.res_evolucao_implantadas[2]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[3] ? response.data.res_evolucao_implantadas[3]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[4] ? response.data.res_evolucao_implantadas[4]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[5] ? response.data.res_evolucao_implantadas[5]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[6] ? response.data.res_evolucao_implantadas[6]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[7] ? response.data.res_evolucao_implantadas[7]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[8] ? response.data.res_evolucao_implantadas[8]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[9] ? response.data.res_evolucao_implantadas[9]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[10] ? response.data.res_evolucao_implantadas[10]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_implantadas[11] ? response.data.res_evolucao_implantadas[11]['total_mes'] || 0 : 0,
                ],
              },
              {
                label: 'Aguardando Implantação',
                backgroundColor: '#00bb7e',
                data: [
                  response.data.res_evolucao_simuladas[0] ? response.data.res_evolucao_simuladas[0]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[1] ? response.data.res_evolucao_simuladas[1]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[2] ? response.data.res_evolucao_simuladas[2]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[3] ? response.data.res_evolucao_simuladas[3]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[4] ? response.data.res_evolucao_simuladas[4]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[5] ? response.data.res_evolucao_simuladas[5]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[6] ? response.data.res_evolucao_simuladas[6]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[7] ? response.data.res_evolucao_simuladas[7]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[8] ? response.data.res_evolucao_simuladas[8]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[9] ? response.data.res_evolucao_simuladas[9]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[10] ? response.data.res_evolucao_simuladas[10]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_simuladas[11] ? response.data.res_evolucao_simuladas[11]['total_mes'] || 0 : 0,
                ],
              }
            ]
          }
          this.loadingBase = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },

    monterEvolucaoConsumo() {
      this.loadingBaseConsumo = true;
      this.evolucaoConsumo = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Consumo',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: 0.4
          },
          {
            label: 'Média',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: '#999',
            borderColor: '#999',
            tension: 0.4
          },

        ]
      };

      let data = {
        data_inicial: this.ano + "-" + this.mes + "-01",
        data_final: this.ano + "-" + this.mes + "-01",
        status: this.staus_unidades,
        tipo_tensao: this.tipo_tensao,
        tipo_pagamento: this.tipo_pagamento,
        ano: this.ano
      }
      this.axios
        .post("/home/evolucao-consumo", data)
        .then((response) => {

          this.evolucaoConsumo = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
              {
                label: 'Consumo',
                data: [
                  response.data.res_evolucao_consumo[0] ? response.data.res_evolucao_consumo[0]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[1] ? response.data.res_evolucao_consumo[1]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[2] ? response.data.res_evolucao_consumo[2]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[3] ? response.data.res_evolucao_consumo[3]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[4] ? response.data.res_evolucao_consumo[4]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[5] ? response.data.res_evolucao_consumo[5]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[6] ? response.data.res_evolucao_consumo[6]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[7] ? response.data.res_evolucao_consumo[7]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[8] ? response.data.res_evolucao_consumo[8]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[9] ? response.data.res_evolucao_consumo[9]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[10] ? response.data.res_evolucao_consumo[10]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_consumo[11] ? response.data.res_evolucao_consumo[11]['total_mes'] || 0 : 0,
                ],
                fill: false,
                backgroundColor: '#2f4860',
                borderColor: '#2f4860',
                tension: 0.4
              },
              {
                label: 'Médio',
                data: [
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                ],
                fill: false,
                backgroundColor: '#999',
                borderColor: '#999',
                tension: 0.4
              },

            ]
          };
          this.loadingBaseConsumo = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },

    monterEvolucaoValor() {
      this.loadingBaseValor = true;
      this.evolucaoValor = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
          {
            label: 'Consumo',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: 0.4
          },
          {
            label: 'Média',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: '#999',
            borderColor: '#999',
            tension: 0.4
          },

        ]
      };

      let data = {
        data_inicial: this.ano + "-" + this.mes + "-01",
        data_final: this.ano + "-" + this.mes + "-01",
        status: this.staus_unidades,
        tipo_tensao: this.tipo_tensao,
        tipo_pagamento: this.tipo_pagamento,
        ano: this.ano
      }
      this.axios
        .post("/home/evolucao-valor", data)
        .then((response) => {

          this.evolucaoValor = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            datasets: [
              {
                label: 'Valor',
                data: [
                  response.data.res_evolucao_valor[0] ? response.data.res_evolucao_valor[0]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[1] ? response.data.res_evolucao_valor[1]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[2] ? response.data.res_evolucao_valor[2]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[3] ? response.data.res_evolucao_valor[3]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[4] ? response.data.res_evolucao_valor[4]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[5] ? response.data.res_evolucao_valor[5]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[6] ? response.data.res_evolucao_valor[6]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[7] ? response.data.res_evolucao_valor[7]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[8] ? response.data.res_evolucao_valor[8]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[9] ? response.data.res_evolucao_valor[9]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[10] ? response.data.res_evolucao_valor[10]['total_mes'] || 0 : 0,
                  response.data.res_evolucao_valor[11] ? response.data.res_evolucao_valor[11]['total_mes'] || 0 : 0,
                ],
                fill: false,
                backgroundColor: '#2f4860',
                borderColor: '#2f4860',
                tension: 0.4
              },
              {
                label: 'Média',
                data: [
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                  response.data.media ? response.data.media || 0 : 0,
                ],
                fill: false,
                backgroundColor: '#999',
                borderColor: '#999',
                tension: 0.4
              },

            ]
          };
          this.loadingBaseValor = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },

    montarHome() {
      this.loadingConsumo = true;
      this.consumoTotal = '';
      let data = {
        data: this.ano + "-" + "0"+this.mes + "-01",
        status: this.status,
        modelo: this.modelo,
      }
      this.axios
        .post("/home/dados", data)
        .then((response) => {
          this.faturas_implantadas = response.data.faturas_implantadas;
          this.xml_capturados = response.data.xml_capturados;
          this.xml_nao_capturados = response.data.xml_nao_capturados;
          this.xml_enviados = response.data.xml_enviados;
          this.xml_enviados_erro = response.data.xml_enviados_erro;
          this.xml_total = response.data.xml_total;
          
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Usuário ou senha inválida!",
            life: 3000,
          });
        });
    },
    formatCurrency(value) {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef',
            }
          },
        }
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#ebedef'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#ebedef'
            },
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
        }
      };
    }
  }
}
</script>
<style scoped>
.text-900 {
  color: #666 !important;
  font-weight: bold;
}

.card h5 {
  color: #666 !important;
  font-weight: bold;
}

.p-chart {
  margin: 0 auto;
}

.block,
.text-500,
.font-medium,
.mb-3 {
  font-size: 18px;
  font-weight: bold;
  color: #01a2b4 !important;
}

.titulo {
  height: 50px;

}

label {
  color: #666;
}

.valor {
  white-space: nowrap;
  color: #ccc !important;
  font-size: 30px !important;
  text-align: center;
  font-weight: bold !important;
  display: inline-block;
  margin-top: 30px;
}

@media only screen and (min-width: 1201px) and (max-width: 1920px) {
  /* Estilos para desktops padrão */

  /* Estilos para laptops e desktops pequenos */

  .titulo {
    height: 50px;


  }

  .valor {
    white-space: nowrap;
    color: #666 !important;
    font-size: 25px !important;
    text-align: center;
    font-weight: bold;
    display: inline-block;
  }


  .card {
    min-height: 130px;
    ;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {

  /* Estilos para laptops e desktops pequenos */
  label {
    height: 30px;
  }

  .valor {
    font-size: 20px !important;
  }
}
</style>