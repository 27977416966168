import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [

    {
        path: '/recuperar-senha/:hash',
        name: 'Recuperar Senha',
        component: () => import('./pages/Recuperar.vue')
    },
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            //dashboard
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            //Usuários
            {
                path: '/usuarios',
                name: 'Usuários',
                component: () => import('./paginas/usuarios/index.vue')
            },
            {
                path: '/empresas',
                name: 'Empresas',
                component: () => import('./paginas/empresas/index.vue')
            },
            //Tipos de Planilhas
         
            //Faturas
            {
                path: '/faturas/implantadas',
                name: 'Faturas Implantadas',
                component: () => import('./paginas/faturas/implantadas.vue')
            },
            {
                path: '/faturas/erros',
                name: 'Faturas XML - Erros',
                component: () => import('./paginas/faturas/erros.vue')
            },
            {
                path: '/xml/capturados',
                name: 'XML Capturados',
                component: () => import('./paginas/xml/capturados.vue')
            },
            {
                path: '/capturados/enviados',
                name: 'Enviados BRNotas',
                component: () => import('./paginas/xml/enviados.vue')
            },
            {
                path: '/xml/implantar-xml',
                name: 'Implantar XML',
                component: () => import('./paginas/xml/implantar.vue')
            },
            {
                path: '/xml/processar-lote',
                name: 'Processar Lote',
                component: () => import('./paginas/xml/enviar.vue')
            },
            {
                path: '/relatorios/envio',
                name: 'Relatório de Envios',
                component: () => import('./paginas/relatorios/envios.vue')
            },
            //Relatório de Energia Injetada
            {
                path: '/energia',
                name: 'Relatório de Energia Injetada',
                component: () => import('./paginas/energia/energia.vue')
            },
            //Importar de Energia Injetada
            {
                path: '/importar',
                name: 'Importar de Energia Injetada',
                component: () => import('./paginas/importar/index.vue')
            },
            //Base 1 - Importada
            {
                path: '/base1',
                name: 'Base 1 - Importada',
                component: () => import('./paginas/planilhas/base1.vue')
            },
            //Base 2 - Importada
            {
                path: '/base2',
                name: 'Base 2 - Importada',
                component: () => import('./paginas/planilhas/base2.vue')
            },
            //Base 3 - Importada
            {
                path: '/base3',
                name: 'Base 3 - Importada',
                component: () => import('./paginas/planilhas/base3.vue')
            },
            //Importar Planilha Fornecedor
            {
                path: '/fornecedor-planilha',
                name: 'Importar Planilha Fornecedor',
                component: () => import('./paginas/fornecedores/importar.vue')
            },
            //Planilha Fornecedor Importada
            {
                path: '/planilha-importada',
                name: 'Planilha Fornecedor Importada',
                component: () => import('./paginas/planilhas/fornecedor.vue')
            },
            //Relatório Consolidação
            // Concessionarias
            {
                path: '/concessionarias',
                name: 'Concessionarias',
                component: () => import('./paginas/concessionarias/dadosbasicos.vue')
            },
            //Concessionárias Tipos
            {
                path: '/concessionarias/tipos',
                name: 'Concessionarias-tipos',
                component: () => import('./paginas/concessionarias/tipos.vue')
            },
            // Unidades
            {
                path: '/unidades/dados-basicos',
                name: 'Unidades',
                component: () => import('./paginas/unidades/dadosbasicos.vue')
            },
            {
                path: '/unidades/tipos-tensao',
                name: 'Unidades Tipos de Tensão',
                component: () => import('./paginas/unidades/tiposdetensao.vue')
            },
            {
                path: '/unidades/tipos-de-insumo',
                name: 'Unidades Tipos de Insumos',
                component: () => import('./paginas/unidades/tiposdeinsumos.vue')
            },
            // Tipos de modadalidade tarifaria
            {
                path: '/unidades/tipos-modalidade-tarifaria',
                name: 'Tipos de modalidade tarifaria',
                component: () => import('./paginas/unidades/tiposmodalidadetarifaria.vue')
            },
            // Classes Tarifária
            {
                path: '/unidades/tipos-classes-tarifarias',
                name: 'Classes Tarifária',
                component: () => import('./paginas/unidades/classestarifaria.vue')
            },
            // Tipos de Sub Classe
            {
                path: '/unidades/tipos-subclasse',
                name: 'Tipos de ',
                component: () => import('./paginas/unidades/tipossubclasse.vue')
            },
            // Sub Mercado
            {
                path: '/unidades/tipos-submercado',
                name: 'Sub-Mercado',
                component: () => import('./paginas/unidades/submercado.vue')
            }


        ]
    },
    {
        path: '/',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    { path: '/:pathMatch(.*)*', component: () => import('./pages/NotFound.vue') },

];

const router = createRouter({
    mode: "history",
    history: createWebHashHistory(),
    routes,
});

export default router;
